import React from 'react';

import Layout from '../components/Layout';

import { StaticImage } from 'gatsby-plugin-image';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const OurStory = (props) => {
  

  return (<Layout fullMenu location={props.location} title="Our Story">
    <article id="main">
      <header name="OUR STORY">
        <h2>Our Story</h2>
        {/* <p>Aliquam ut ex ut interdum donec amet imperdiet eleifend</p> */}
      </header>
      <section className="wrapper style5 story">
        <div className="inner">
            <h1 class="carousel-title">The Meeting</h1>
            <Carousel
              infiniteLoop={true}
              centerMode={true}
              emulateTouch={true}
              showArrows={true}  
              dynamicHeight={false}
            >
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/first-picture.png"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/camp-test.png"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/camp-drumming.jpg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/group.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 2</p> */}
                </div>
            </Carousel>
          <br />
          <p>
            <p style={{"fontStyle": "italic"}}>In 2013, we met for the first time as teenagers in the same church camp team. We clicked instantly and spent all of spring break talking on the phone since neither of us could drive. "They Don't Know About Us"  by One Direction was the theme song of our relationship. </p>
          </p>
          <br />
          <hr />
        </div>


        <div className="inner">
            <h1 class="carousel-title">The Journey</h1>
            <Carousel
              infiniteLoop={true}
              centerMode={true}
              emulateTouch={true}
              showArrows={true}  
              dynamicHeight={true}
            >
                
                <div>
                    <StaticImage
                      // src="../assets/images/linhandjames/xxlinhs-graduation2.png"
                      src="../assets/images/linhandjames/linhs-graduation2.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/james-band.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                {/* <div>
                    <StaticImage
                      src="../assets/images/linhandjames/hair.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                </div> */}
                {/* <div>
                    <StaticImage
                      src="../assets/images/linhandjames/hugging.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                </div> */}
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/xichlo.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 3</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/old-lantern.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 2</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/linhs-graduation.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/fried-hair.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
            </Carousel>
          <br />
          <p>
            <p style={{"fontStyle": "italic"}}>Our high school relationship was short-lived but we remained close friends as we went through life on two very different paths. We bonded over our shared experience of losing a loved one but also our journey towards healing and growing. James worked various part-time jobs while he was searching for his passion. I followed the traditional path of college and graduate school to pursue a doctorate in dentistry. After a few years of searching, James discovered his love for coding and began studying to develop his skills as a software engineer. I moved to San Antonio for dental school and he moved to Dallas to focus on his studies. </p>
          </p>
          <br />
          <hr />
        </div>

        <div className="inner">
            <h1 class="carousel-title">The Happy Ending</h1>
            <Carousel
              infiniteLoop={true}
              centerMode={true}
              emulateTouch={true}
              showArrows={true}  
              dynamicHeight={true}
            >
                <div>
                    <StaticImage
                      // src="../assets/images/linhandjames/new-years.png"
                      src="../assets/images/linhandjames/new-years.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/arcade.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/zoo.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/hana.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/wedding.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/shadow.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/face-mask.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/car-icecream.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/boat.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/mirror-selfie.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/tet2.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/new-house.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/skating.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/i-love-you-so-machi.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/coffee.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/new-york.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/water-lantern.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/proposal.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
                <div>
                    <StaticImage
                      src="../assets/images/linhandjames/linh-ring.jpeg"
                      alt=""
                      placeholder="blurred"
                    />
                    {/* <p className="legend">Legend 1</p> */}
                </div>
            </Carousel>
          <br />
          <p>
            <p style={{"fontStyle": "italic"}}> As time passed, we realized our friendship was meant to be more. And the rest is history...</p>
          </p>
          <br />
          <hr />
        </div>

      </section>
    </article>
  </Layout>
);
}

export default OurStory;
